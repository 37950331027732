<template>
  <component v-if="svgComponent" :is="svgComponent" :width="width" :height="height" :fill="fill"/>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 12
    },
    height: {
      type: [Number, String],
      default: 12
    },
    iconName: {
      type: String,
      default: ''
    },
    fill: {
      type: String,
      default: '#9CA6BA'
    }
  },
  computed: {
    svgComponent () {
      if (this.iconName) {
        return () => import('../components/svg-icons/' + this.iconName + '.vue')
      } else {
        return null
      }
    }
  }
}
</script>
